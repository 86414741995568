import React from "react";

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center h-screen ">
      <div className="container">
        <div className="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Coming <span class="text-red-400">Soon</span>
            </h2>
            <p className="text-md md:text-xl mt-10">
              Cooking Class Kuching by Award Winning Indah
            </p>
          </div>
          <div className="flex flex-wrap mt-10 justify-center">
            <div className="m-3">
              <a
                href="https://www.facebook.com/IndahHouseKuching"
                title="Indah On Facebook"
                className="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
              >
                <span className="mx-auto">Facebook</span>
              </a>
            </div>
            <div className="m-3">
              <a
                href="https://www.instagram.com/indahhousekuching/?hl=en"
                title="Indah On Instagram"
                className="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-red-300 hover:border-red-400 hover:bg-red-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
              >
                <span className="mx-auto">Instagram</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
