import * as React from "react";

import Navbar from "./Navbar";
import NewsletterSection from "./NewsletterSection";

const Layout = (props) => {
  return (
    <div>
      <Navbar />
      {props.children}
      <NewsletterSection />
    </div>
  );
};

export default Layout;
