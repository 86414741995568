import React from "react";
import Layout from "../components/Layout";
import ComingSoon from "../components/ComingSoon";

export default function BlogPage() {
  return (
    <Layout>
      <ComingSoon />
    </Layout>
  );
}
